<template>
  <div class="page-demo">
    <iframe
      src="https://yolocast-test.yololiv.com/v20211026-1515/live/00cfdaa808a34ff694a180645a3d9760"
      frameborder="0"
      style="width: 100%; height: 100%; min-height: 100vh"
    ></iframe>
  </div>
</template>

<script>
// import { fabric } from 'fabric'
export default {
  mounted() {
    // var canvas = new fabric.Canvas('canvas', { backgroundColor: 'grey' })
    // var rect = new fabric.Rect({
    //   left: 100,
    //   top: 100,
    //   fill: 'red',
    //   width: 20,
    //   height: 20
    // })
    // canvas.add(rect)
  }
}
</script>
